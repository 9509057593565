window.addEventListener('load', async () => {
    if (!('indexedDB' in window)) {
        a4ilog.warn('This browser doesn\'t support IndexedDB. Perfomances may be impacted');
        return;
    }

    const db_name = 'memshare';
    const db_version = 2;

    let idb = window.indexedDB;

    // Open a db instance
    let request = idb.open(db_name, db_version);

    request.onerror = (event) => a4ilog.error('Database error: ' + event.target.errorCode);

    // Create or update the database structure
    request.onupgradeneeded = (event) => {
        const db = event.target.result;

        const offlineStore = db.createObjectStore(db_name, {keyPath: 'key'});
        offlineStore.createIndex('value', 'value');
    };

    request.onsuccess = (event) => {
        // Get db instance
        const db = event.target.result;

        // Open a transaction
        const transaction = db.transaction([db_name], 'readwrite');

        // Select offline-pages object store (table)
        const memshare = transaction.objectStore(db_name);
        const connected = memshare.get('connected');

        connected.onsuccess = () => {
            const connected = document.cookie.search('user_connected') >= 0;
            memshare.put({key: 'connected', value: connected});
        }
    };
});